import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '@common/config/configurations';
import { IVeevaConnectionPayload } from '@administration/interfaces/IVeeva';
import { displayToast } from '@administration/components/toast/toast.slice';

export const VeevaActionApi = createApi({
  reducerPath: 'veevaActions',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    checkVeevaConnection: builder.query({
      query: () => ({
        pathname: `/${config.REACT_APP_API_VERSION}/providers`,
        method: 'GET',
      }),
      transformResponse: (response: any) => response[0],
    }),
    getVeevaSuggestion: builder.query<any, IVeevaConnectionPayload>({
      query: ({ providerId, connectionId, suggestionId }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/providers/${providerId}/connections/${connectionId}/suggestions/${suggestionId}`,
        method: 'GET',
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(displayToast({ keyMessage: 'GLOBAL_SUCCESS', type: 'success' }));
          }
        } catch (error: any) {
          const errorMsg = error?.error?.data?.message || 'GLOBAL_ERROR';
          dispatch(displayToast({ keyMessage: errorMsg, type: 'error' }));
        }
      },
    }),
    updateVeevaSuggestion: builder.mutation<any, IVeevaConnectionPayload>({
      query: ({ providerId, connectionId, suggestionId }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/providers/${providerId}/connections/${connectionId}/suggestions/${suggestionId}`,
        method: 'POST',
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(displayToast({ keyMessage: 'GLOBAL_SUCCESS', type: 'success' }));
          }
        } catch (error) {
          dispatch(displayToast({ keyMessage: 'GLOBAL_ERROR', type: 'error' }));
        }
      },
    }),
    deleteVeevaSuggestion: builder.mutation<any, IVeevaConnectionPayload>({
      query: ({ providerId, connectionId, suggestionId }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/providers/${providerId}/connections/${connectionId}/suggestions/${suggestionId}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(displayToast({ keyMessage: 'GLOBAL_SUCCESS', type: 'success' }));
          }
        } catch (error) {
          dispatch(displayToast({ keyMessage: 'GLOBAL_ERROR', type: 'error' }));
        }
      },
    }),
  }),
});

export const {
  useCheckVeevaConnectionQuery,
  useLazyGetVeevaSuggestionQuery,
  useUpdateVeevaSuggestionMutation,
  useDeleteVeevaSuggestionMutation,
} = VeevaActionApi;
