"use strict";
export const selectSuggestions = (state) => state.suggestions;
export const selectAdminSidebar = (state) => state.adminSidebar;
export const selectUserAdminFilters = (state) => state.userAdminFilters;
export const selectAdminUsers = (state) => state.adminUsers;
export const selectUserFilterSearch = (state) => state.userFilterSearch;
export const selectToast = (state) => state.toast;
export const selectCountries = (state) => state.countriesSlice;
export const selectUsecasePanel = (state) => state.usecasePanel;
export const selectUsecases = (state) => state.usecases;
export const selectAudit = (state) => state.audit;
export const selectHcps = (state) => state.hcps;
export const selectHcos = (state) => state.hcos;
export const selectProducts = (state) => state.products;
export const selectUsers = (state) => state.users;
export const selectSuggestionsFilter = (state) => state.suggestionsFilter;
export const selectPersonalSettings = (state) => state.personalSettings;
export const selectMap = (state) => state.map;
export const selectDirections = (state) => state.directions;
export const selectTheme = (state) => state.theme;
export const selectUserDetails = (state) => state.userDetails;
export const selectUserDetailsModal = (state) => state.userDetailsModal;
export const selectAppSetup = (state) => state.appSetup;
export const selectMSTeamsSetup = (state) => state.msteamsSetup;
export const selectMSTeamsActions = (state) => state.msTeamsActions;
export const selectUsecaseEvent = (state) => state.event;
export const selectDashboard = (state) => state.dashboard;
export const selectNotificationSettings = (state) => state.notificationSettings;
export const selectGroupMembers = (state) => state.groupMembers;
export const selectCountriesFilter = (state) => state.countriesFilterSlice;
export const selectCountriesFilterSearch = (state) => state.countriesFilterSearch;
export const selectActiveCountries = (state) => state.activeCountriesSlice;
export const selectSurvey = (state) => state.survey;
export const selectSurveyForm = (state) => state.surveyForm;
export const selectSuggestionDismiss = (state) => state.suggestionDismiss;
export const selectVeevaIntegration = (state) => state.veevaIntegration;
export const selectModal = (state) => state.modal;
export const selectUsecase = (state) => state.usecase;
