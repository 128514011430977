import { AxiosResponse } from 'axios';
import { instance } from '../../../common/apis/baseApi';
import { config } from '../../../common/config/configurations';
import { IQueryKey } from '@administration/interfaces/IQueryKey';

export const searchUsers = async (
  queryKey: IQueryKey,
  _organizationID?: string | undefined,
  expand?: string,
  extra_columns?: string,
): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/users/search`, {
    params: {
      expand: expand || '',
      extra_columns: extra_columns || '',
      query: queryKey.search,
      $count: true,
      page_size: queryKey.pagination?.pageSize || 10,
      offset: queryKey.pagination?.pageIndex || 0,
    },
  });
};

export const deleteUser = (id: string): Promise<AxiosResponse> => {
  return instance.delete(`${config.REACT_APP_API_VERSION}/users/${id}`);
};

export const impersonateUser = (userId: string): Promise<AxiosResponse> => {
  localStorage.removeItem('user_role');
  return instance.post(`${config.REACT_APP_API_VERSION}/authentication/oauth2/grant/${userId}`);
};

export const unImpersonateUser = (): Promise<AxiosResponse> => {
  localStorage.removeItem('user_role');
  return instance.delete(`${config.REACT_APP_API_VERSION}/authentication/oauth2/grant`);
};

export const getUserDetails = (id: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/users/${id}`, {
    params: {
      expand: 'addresses',
    },
  });
};

// export const patchUserDetail = (id: string, payload: any): Promise<AxiosResponse> => {
//   return instance.patch(`${config.REACT_APP_API_VERSION}/users/${id}`, payload);
// };

export const patchUserEmail = ({
  userId,
  emailId,
  payload,
}: {
  userId: string;
  emailId: string;
  payload: any;
}): Promise<AxiosResponse> => {
  return instance.patch(
    `${config.REACT_APP_API_VERSION}/users/${userId}/emails/${emailId}`,
    payload,
  );
};

export const patchUserAddress = ({
  userId,
  addressId,
  payload,
}: {
  userId: string;
  addressId: string;
  payload: any;
}): Promise<AxiosResponse> => {
  return instance.patch(
    `${config.REACT_APP_API_VERSION}/users/${userId}/addresses/${addressId}`,
    payload,
  );
};
