import { IApiStatus } from '@administration/interfaces/IApi';
import { INotificationPolicy } from '@administration/interfaces/INotifications';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@common/apis/axios-base-query';
import { config } from '@common/config/configurations';
import { IPagination } from '@administration/interfaces/IPagination';
import { displayToast } from '@administration/components/toast/toast.slice';
import { clearState, prepareNotificationForEdit } from './notifications-form.slice';

const NotificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    getNotificationsPolicy: builder.query<
      { data: INotificationPolicy[]; count: number },
      IPagination
    >({
      query: ({ pageSize, offset }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/policies/notifications`,
        params: { page_size: pageSize, offset, $count: true },
      }),
      transformResponse: (response: { values: INotificationPolicy[]; $count: number }) => {
        return {
          data: response.values,
          count: response.$count,
        };
      },
      providesTags: (result, _error, arg) =>
        result
          ? [{ type: 'Notifications', id: `notifications-${arg.pageSize}_${arg.offset}` }]
          : [],
    }),
    deleteNotificationSettings: builder.mutation<IApiStatus, string>({
      query: (id) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/policies/notifications/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _error) => (result ? [{ type: 'Notifications' }] : []),
    }),
    fetchNotificationSettings: builder.query<
      INotificationPolicy[],
      { countryId: string; productId: string }
    >({
      query: ({ countryId, productId }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/policies/notifications?product=${productId}&country=${countryId}`,
      }),
      transformResponse: (response: { values: INotificationPolicy[] }) => response.values,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.length > 0) {
            dispatch(
              displayToast({ type: 'success', keyMessage: 'NOTIFICATION_SETTINGS_ALREADY_SAVED' }),
            );
            dispatch(prepareNotificationForEdit(data[0]));
          } else {
            dispatch(displayToast({ type: 'info', keyMessage: 'GLOBAL_NO_SETTINGS_SAVED' }));
          }
        } catch (error) {
          dispatch(clearState());
          dispatch(displayToast({ type: 'error', keyMessage: 'GLOBAL_FETCHING_ERROR' }));
        }
      },
      providesTags: (result, _error, arg) =>
        result
          ? [{ type: 'Notifications', id: `notifications-${arg.countryId}_${arg.productId}` }]
          : [],
    }),
    saveNotificationSettings: builder.mutation<IApiStatus, any>({
      query: (payload) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/policies/notifications`,
        method: 'POST',
        data: payload,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Notifications' }] : []),
    }),
    updateNotificationSettings: builder.mutation<IApiStatus, any>({
      query: ({ notificationId, payload }) => ({
        pathname: `/${config.REACT_APP_API_VERSION}/policies/notifications/${notificationId}`,
        method: 'PUT',
        data: payload,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'Notifications' }] : []),
    }),
  }),
});

export const {
  useGetNotificationsPolicyQuery,
  useDeleteNotificationSettingsMutation,
  useLazyFetchNotificationSettingsQuery,
  useSaveNotificationSettingsMutation,
  useUpdateNotificationSettingsMutation,
} = NotificationsApi;

export { NotificationsApi };
