"use strict";
import { jsx } from "react/jsx-runtime";
import { Navigate } from "react-router-dom";
import { ProtectedRoute } from "../common/components/authentication/protected";
import { PermittedRoute } from "../common/components/permissions/permittedRoute";
import { lazyWithRetry } from "../utils/lazy-retry";
import Loadable from "../common/components/loadable";
const AdminHome = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/Home"))
);
const NotificationsSettings = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/global-settings"))
);
const Usecases = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/usecase"))
);
const AdminUsers = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/users"))
);
const UserPage = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/user-page"))
);
const IQVIADataUpload = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/iqvia"))
);
const Events = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/events"))
);
const AdminGroups = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/groups"))
);
const GroupMembers = Loadable(
  lazyWithRetry(
    () => import("../administration/pages/administration/dashboard/groups/group-members")
  )
);
const AdminAudiences = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/audiences"))
);
const SuggestionsSettings = Loadable(
  lazyWithRetry(
    () => import("../administration/pages/administration/dashboard/suggestions-settings")
  )
);
const Audit = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/audit"))
);
const Countries = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/countries"))
);
const HCPAccounts = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/accounts/hcp"))
);
const CycleInsights = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/cycles"))
);
const VeevaSetup = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/veeva-setup"))
);
const DysportUpload = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/dysport-upload"))
);
const SurveyPage = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/survey/screens"))
);
const SurveyEditPage = Loadable(
  lazyWithRetry(
    () => import("../administration/pages/administration/dashboard/survey/screens/survey-edit")
  )
);
const SurveyCreatePage = Loadable(
  lazyWithRetry(
    () => import("../administration/pages/administration/dashboard/survey/screens/survey-create")
  )
);
const AppSetup = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/app-setup"))
);
const AdminDashboardAdoption = Loadable(
  lazyWithRetry(
    () => import("../administration/pages/administration/dashboard/dashboard/adoption")
  )
);
const AdminDashboardSetup = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/dashboard/setup"))
);
const MSTeamsSetup = Loadable(
  lazyWithRetry(() => import("../administration/pages/administration/dashboard/msteams-setup"))
);
export const adminRoutes = {
  path: "/administration",
  element: /* @__PURE__ */ jsx(ProtectedRoute, { children: /* @__PURE__ */ jsx(
    PermittedRoute,
    {
      accessRules: { roles: ["country", "data", "it", "platform", "global_admin"] },
      children: /* @__PURE__ */ jsx(AdminHome, { mode: "web" })
    }
  ) }),
  children: [
    {
      index: true,
      element: /* @__PURE__ */ jsx(Navigate, { to: "dashboard/adoption", replace: true })
    },
    {
      path: "settings/notifications",
      element: /* @__PURE__ */ jsx(NotificationsSettings, { mode: "web" })
    },
    {
      path: "settings/usecases",
      element: /* @__PURE__ */ jsx(Usecases, { mode: "web" })
    },
    {
      path: "settings/suggestions",
      element: /* @__PURE__ */ jsx(SuggestionsSettings, { mode: "web" })
    },
    {
      path: "settings/countries",
      element: /* @__PURE__ */ jsx(Countries, { mode: "web" })
    },
    {
      path: "applications/app-setup",
      element: /* @__PURE__ */ jsx(AppSetup, {})
    },
    {
      path: "applications/msteams-setup",
      element: /* @__PURE__ */ jsx(MSTeamsSetup, { mode: "web" })
    },
    {
      path: "settings/survey",
      element: /* @__PURE__ */ jsx(SurveyPage, {})
    },
    {
      path: "settings/survey/:surveyId/edit",
      element: /* @__PURE__ */ jsx(SurveyEditPage, {})
    },
    {
      path: "settings/survey/create",
      element: /* @__PURE__ */ jsx(SurveyCreatePage, {})
    },
    {
      path: "data/iqvia/upload",
      element: /* @__PURE__ */ jsx(IQVIADataUpload, { mode: "web" })
    },
    {
      path: "data/events/upload",
      element: /* @__PURE__ */ jsx(Events, {})
    },
    {
      path: "data/audit",
      element: /* @__PURE__ */ jsx(Audit, { mode: "web" })
    },
    {
      path: "data/veeva/dysport-upload",
      element: /* @__PURE__ */ jsx(DysportUpload, {})
    },
    {
      path: "data/veeva/cycle-insights",
      element: /* @__PURE__ */ jsx(CycleInsights, { mode: "web" })
    },
    {
      path: "applications/veeva/setup",
      element: /* @__PURE__ */ jsx(VeevaSetup, {})
    },
    {
      path: "provisioning/users",
      element: /* @__PURE__ */ jsx(AdminUsers, { mode: "web" })
    },
    {
      path: "provisioning/users/:userId",
      element: /* @__PURE__ */ jsx(UserPage, {})
    },
    {
      path: "provisioning/groups",
      element: /* @__PURE__ */ jsx(AdminGroups, { mode: "web" })
    },
    {
      path: "provisioning/groups/:groupId/members",
      element: /* @__PURE__ */ jsx(GroupMembers, {})
    },
    {
      path: "provisioning/audiences",
      element: /* @__PURE__ */ jsx(AdminAudiences, { mode: "web" })
    },
    {
      path: "dashboard/adoption",
      element: /* @__PURE__ */ jsx(AdminDashboardAdoption, { mode: "web" })
    },
    {
      path: "dashboard/setup",
      element: /* @__PURE__ */ jsx(AdminDashboardSetup, { mode: "web" })
    },
    {
      path: "accounts/hcps",
      element: /* @__PURE__ */ jsx(HCPAccounts, { mode: "web" })
    }
  ]
};
