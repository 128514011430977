// types.ts
import { MRT_RowSelectionState } from 'material-react-table';
import { IUpdateSurveyStatus } from './types';
import {
  updateSurvey,
  removeSurvey,
} from '@administration/pages/administration/dashboard/survey/actions/survey.actions';
import { deleteSelectedUsecases } from '@administration/pages/administration/dashboard/usecase/actions/usecase.action';
import { deleteNotificationSettings } from '@administration/pages/administration/dashboard/global-settings/slices/notifications.action';

export const CONFIRMATION_ACTION_TYPE = 'CONFIRMATION_NEEDED' as const;
export const UPDATE_SURVEY_STATUS = 'UPDATE_SURVEY_STATUS' as const;
export const DELETE_SURVEY = 'DELETE_SURVEY' as const;
export const DELETE_USECASES = 'DELETE_USECASES' as const;
export const DELETE_NOTIFICATION_SETTINGS = 'DELETE_NOTIFICATION_SETTINGS' as const;

export const ACTIONS_REGISTRY = {
  [UPDATE_SURVEY_STATUS]: (payload: IUpdateSurveyStatus) =>
    updateSurvey({ id: payload.id, status: payload.status }),
  [DELETE_SURVEY]: (payload: string | MRT_RowSelectionState) => removeSurvey(payload),
  [DELETE_USECASES]: (payload: MRT_RowSelectionState) => deleteSelectedUsecases(payload),
  [DELETE_NOTIFICATION_SETTINGS]: (notificationId: string) =>
    deleteNotificationSettings(notificationId),
} as const;
