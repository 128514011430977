import { t } from 'i18next';

import {
  CALL_INVITED,
  CALL_ATTENDANCE,
  EVENT_FOLLOWUP,
  EVENT_SECOND_FOLLOWUP,
  NAMES_REGEX,
  OPTIONS,
  COUNTRY_REGEX,
  PRODUCT_REGEX,
} from '../constants';
import {
  IAudiences,
  IPayload,
  TypeTimeline,
  TypeCallTarget,
  ENotificationSentence,
  ENotificationTitle,
} from '@administration/interfaces/IUsecases';
import { IUser } from '@common/interfaces/IUser';
import { RootState } from '@store/types';

const POST_OPTIONS = OPTIONS.map((option) => option.name);
interface IPreparePayloadArgs {
  isCreation: boolean;
  usecase: RootState['usecase'];
}

export const preparePayload = (args: IPreparePayloadArgs): IPayload => {
  const { isCreation, usecase } = args;
  const { panel, audience, users, paramaters, event, marketshare, approvedEmail, survey, sales } =
    usecase;

  if (!audience.product || !audience.country) {
    throw new Error('Audience product or country is missing');
  }

  const rules = prepareRules(
    audience.product.id,
    audience.country.id,
    users.usersToBeInclude.values,
    users.usersToBeExclude.values,
  );
  const audiences: IAudiences[] = [
    {
      name: `${audience.country.name} - ${audience.product.name}`,
      status: 'enabled',
      rule: rules,
    },
  ];

  if (!isCreation) {
    audiences[0]['id'] = audience.audienceId;
  }

  const payload = {
    name: `Campaign,${audience.country.name} - ${audience.product.name}`,
    description: `${audience.product.name} campaign for ${audience.country.name}`,
    status: isCreation ? 'enabled' : panel.status,
    tags: [audience.product.name, audience.country.name],
    audiences: audiences,
    flow: {
      name: 'configuration_type_call_pacing',
      status: 'enabled',
      configuration: {
        mccp: {
          name: 'NBA_CONFIGURATION_MCCP_COVERAGE',
          active: paramaters['NBA_CONFIGURATION_MCCP_COVERAGE'].active,
          value: null,
          can_be_modified: false,
        },
        priorities: {
          name: 'NBA_CONFIGURATION_PRIORITIES',
          active: paramaters['NBA_CONFIGURATION_PRIORITIES'].active,
          value: null,
          can_be_modified: false,
        },
        role: {
          name: 'NBA_CONFIGURATION_ROLE',
          can_be_modified: true,
          value: paramaters['NBA_CONFIGURATION_ROLE'].selectedOption,
          active: paramaters['NBA_CONFIGURATION_ROLE'].active,
          values: POST_OPTIONS,
        },
        relative_strength: {
          name: 'NBA_CONFIGURATION_RELATIVE_STRENGTH',
          can_be_modified: true,
          value: paramaters['NBA_CONFIGURATION_RELATIVE_STRENGTH'].selectedOption,
          active: paramaters['NBA_CONFIGURATION_RELATIVE_STRENGTH'].active,
          values: POST_OPTIONS,
        },
        adoption_ladder: {
          name: 'NBA_CONFIGURATION_ADOPTION_LADDER',
          can_be_modified: true,
          value: paramaters['NBA_CONFIGURATION_ADOPTION_LADDER'].selectedOption,
          active: paramaters['NBA_CONFIGURATION_ADOPTION_LADDER'].active,
          values: POST_OPTIONS,
        },
        top_accounts: {
          name: 'NBA_CONFIGURATION_TOP_ACCOUNTS',
          can_be_modified: true,
          value: paramaters['NBA_CONFIGURATION_TOP_ACCOUNTS'].selectedOption,
          active: paramaters['NBA_CONFIGURATION_TOP_ACCOUNTS'].active,
          values: POST_OPTIONS,
        },
        hcp_specialty: {
          name: 'NBA_CONFIGURATION_HCP_SPECIALTY',
          can_be_modified: true,
          value: paramaters['NBA_CONFIGURATION_HCP_SPECIALTY'].selectedOption,
          active: paramaters['NBA_CONFIGURATION_HCP_SPECIALTY'].active,
          values: POST_OPTIONS,
        },
        hcp_type: {
          name: 'NBA_CONFIGURATION_HCP_TYPE',
          can_be_modified: true,
          value: paramaters['NBA_CONFIGURATION_HCP_TYPE'].selectedOption,
          active: paramaters['NBA_CONFIGURATION_HCP_TYPE'].active,
          values: POST_OPTIONS,
        },
        market_share: {
          name: 'NBA_CONFIGURATION_MARKET_SHARE',
          can_be_modified: true,
          value: marketshare.selectedOption,
          active: marketshare.active,
          values: POST_OPTIONS,
          market_share_values: {
            type: marketshare.settingType,
            activity_thresholds: {
              minimal_activity: {
                value: marketshare.activity_thresholds.minimal_activity.value,
                type: marketshare.activity_thresholds.minimal_activity.type,
              },
              low_alert_level: marketshare.activity_thresholds.low_alert_level,
              high_alert_level: marketshare.activity_thresholds.high_alert_level,
            },
          },
        },
        survey: {
          name: 'NBA_CONFIGURATION_SURVEY',
          can_be_modified: true,
          value: { surveyId: survey.selectedSurvey.value?.id || '' },
          active: survey.active,
        },
        event: {
          name: 'NBA_CONFIGURATION_MCCP_EVENT',
          can_be_modified: true,
          value: null,
          active: event.event.active,
          values: POST_OPTIONS,
          configurations: {
            events: {
              types: event.event.types,
              statuses: event.event.statuses,
              topics: event.event.topics,
              product_ids:
                event.event.products.values && event.event.products.values.length > 0
                  ? event.event.products.values.map((product: any) => product.id)
                  : [audience.product.id],
            },
            calls: [
              {
                idx: 0,
                timeline: 'before' as TypeTimeline,
                target: 'attendee' as TypeCallTarget,
                days: event.event.configurations[CALL_INVITED].days,
                title: 'NBA_EVENT_TITLE_INVITED' as ENotificationTitle.EVENT_INVITED,
                sentence: 'NBA_EVENT_REASON_INVITED' as ENotificationSentence.EVENT_INVITED,
                active: event.event.configurations[CALL_INVITED].active,
                priorities: event.event.configurations[CALL_INVITED].priorities,
                attendee_status: event.event.configurations[CALL_INVITED].attendee_status,
              },
              {
                idx: 1,
                timeline: 'before' as TypeTimeline,
                target: 'attendee' as TypeCallTarget,
                days: event.event.configurations[CALL_ATTENDANCE].days,
                title: 'NBA_EVENT_TITLE_ACCEPTED' as ENotificationTitle.EVENT_ACCEPTED,
                sentence: 'NBA_EVENT_REASON_ACCEPTED' as ENotificationSentence.EVENT_ACCEPTED,
                active: event.event.configurations[CALL_ATTENDANCE].active,
                priorities: event.event.configurations[CALL_ATTENDANCE].priorities,
                attendee_status: event.event.configurations[CALL_ATTENDANCE].attendee_status,
              },
              {
                idx: 0,
                target: 'attendee' as TypeCallTarget,
                timeline: 'after' as TypeTimeline,
                title: 'NBA_EVENT_TITLE_LATE_FOLLOW_UP' as ENotificationTitle.LATE_FOLLOW_UP,
                sentence: 'NBA_EVENT_REASON_LATE_FOLLOW_UP' as ENotificationSentence.LATE_FOLLOW_UP,
                active: event.event.configurations[EVENT_FOLLOWUP].active,
                days: event.event.configurations[EVENT_FOLLOWUP].days,
                priorities: event.event.configurations[EVENT_FOLLOWUP].priorities,
                attendee_status: event.event.configurations[EVENT_FOLLOWUP].attendee_status,
              },
              {
                idx: 1,
                target: 'attendee' as TypeCallTarget,
                timeline: 'after' as TypeTimeline,
                title: 'NBA_EVENT_TITLE_LATE_FOLLOW_UP' as ENotificationTitle.LATE_FOLLOW_UP,
                sentence: 'NBA_EVENT_REASON_LATE_FOLLOW_UP' as ENotificationSentence.LATE_FOLLOW_UP,
                active: event.event.configurations[EVENT_SECOND_FOLLOWUP].active,
                days: event.event.configurations[EVENT_SECOND_FOLLOWUP].days,
                priorities: event.event.configurations[EVENT_SECOND_FOLLOWUP].priorities,
                attendee_status: event.event.configurations[EVENT_SECOND_FOLLOWUP].attendee_status,
              },
            ],
            approved_emails: [],
          },
        },
        approved_email: {
          name: 'NBA_CONFIGURATION_MCCP_APPROVED_EMAIL',
          can_be_modified: true,
          value: null,
          active: approvedEmail.active,
          configuration: {
            approved_email_id: approvedEmail.approvedEmail?.id ?? null,
            frequency: approvedEmail.frequency,
            start_date: approvedEmail.startDate,
            end_date: approvedEmail.endDate,
          },
        },
        sales_volume: {
          name: 'NBA_CONFIGURATION_SALES_VOLUME',
          can_be_modified: true,
          value: null,
          active: sales.isActive,
          configuration: {
            low_threshold: sales.configuration.low_threshold,
            alert_high_loss: sales.configuration.alert_high_loss,
            alert_very_high_loss: sales.configuration.alert_very_high_loss,
          },
        },
      },
    },
  };
  return payload;
};

const prepareRules = (
  productId: string,
  countryId: string,
  usersToInclude: IUser[] | null,
  usersToExclude: IUser[] | null,
) => {
  let ruleExpression = `user.product.id==${productId} AND user.country.id==${countryId}`;

  const includedUsersRule =
    usersToInclude && usersToInclude.map((user: IUser) => `user.id == ${user.id}`).join(' OR ');

  const excludedUsersRule =
    usersToExclude && usersToExclude.map((user: IUser) => `user.id != ${user.id}`).join(' AND ');

  if (excludedUsersRule) {
    ruleExpression = `${ruleExpression} AND ${excludedUsersRule}`;
  }

  if (includedUsersRule) {
    ruleExpression = `${ruleExpression} OR ${includedUsersRule}`;
  }

  return ruleExpression;
};

export const extractCountryFromAudience = (audience: IAudiences) => {
  const matches = audience.name.match(NAMES_REGEX);
  return matches ? matches[1] : 'N/A';
};

export const extractProductFromAudience = (audience: IAudiences) => {
  const matches = audience.name.match(NAMES_REGEX);
  return matches ? matches[2] : 'N/A';
};

export const extractJourneyFromFlow = (flow: any) => {
  const journeyName = flow.name.replace('configuration_type_', '').toUpperCase();
  return journeyName === 'CALL_PACING' ? t('GLOBAL_CALL_PACING') : t('GLOBAL_EVENT_CONGRES');
};

export const extractCountryIdFromAudience = (audience: IAudiences) => {
  const matches = audience.rule.match(COUNTRY_REGEX);
  return matches ? matches[1] : null;
};

export const extractProductIdFromAudience = (audience: IAudiences) => {
  const matches = audience.rule.match(PRODUCT_REGEX);
  return matches ? matches[1] : null;
};

export const validateEntries = (args: any, dispatch: any) => {
  const { usecase } = args;
  const { audience, marketshare, approvedEmail, sales } = usecase;
  if (!audience.country?.id) {
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', keyMessage: 'GLOBAL_COUNTRY_REQUIRED' },
    });
    return false;
  }
  if (!audience.product?.id) {
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', keyMessage: 'GLOBAL_PRODUCT_REQUIRED' },
    });
    return false;
  }
  if (
    marketshare.settingType === 'SPECIFIC' &&
    (!marketshare.activity_thresholds.minimal_activity.value ||
      !marketshare.activity_thresholds.low_alert_level ||
      !marketshare.activity_thresholds.high_alert_level)
  ) {
    dispatch({
      type: 'toast/displayToast',
      payload: {
        type: 'error',
        keyMessage: 'GLOBAL_MARKET_SHARE_REQUIRED_INPUTS',
      },
    });
    return false;
  }
  if (approvedEmail.active && !approvedEmail.approvedEmail) {
    dispatch({
      type: 'toast/displayToast',
      payload: {
        type: 'error',
        keyMessage: 'GLOBAL_APPROVED_EMAIL_REQUIRED',
      },
    });
    return false;
  }
  if(sales.isActive && sales.configuration.low_threshold < 0){
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', message: t('GLOBAL_REQUIRED_NOT_NEGATIVE', { context : t('GLOBAL_ALERT_LOW_THRESHOLD')}) },
    });
    return false;
  }
  if(sales.isActive && sales.configuration.alert_high_loss < 0){
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', message: t('GLOBAL_REQUIRED_NOT_NEGATIVE', { context : t('GLOBAL_ALERT_HIGH_LOSS')}) },
    });
    return false;
  }
  if(sales.isActive && sales.configuration.alert_very_high_loss < 0){
    dispatch({
      type: 'toast/displayToast',
      payload: { type: 'error', message: t('GLOBAL_REQUIRED_NOT_NEGATIVE', { context : t('GLOBAL_ALERT_VERY_HIGH_LOSS')}) },
    });
    return false;
  }
  return true;
};
