"use strict";
export const SUGGESTIONS = "suggestions";
export const ADMIN_SIDEBAR = "adminSidebar";
export const ADMIN_USERS = "adminUsers";
export const ADMIN_USER_FILTERS = "userAdminFilters";
export const USER_FILTER_SEARCH = "userFilterSearch";
export const TOAST = "toast";
export const COUNTRIES = "countriesSlice";
export const USECASE_PANEL = "usecasePanel";
export const USECASES = "usecases";
export const AUDIT = "audit";
export const HCPS = "hcps";
export const HCOS = "hcos";
export const PRODUCTS = "products";
export const USERS = "users";
export const SUGGESTIONS_FILTER = "suggestionsFilter";
export const PERSONAL_SETTINGS = "personalSettings";
export const MAP = "map";
export const DIRECTIONS = "directions";
export const THEME = "theme";
export const USER_DETAILS = "userDetails";
export const USER_DETAILS_MODAL = "userDetailsModal";
export const APP_SETUP = "appSetup";
export const MS_TEAMS_SETUP = "msteamsSetup";
export const MS_TEAMS_SETUP_ACTIONS = "msTeamsActions";
export const EVENT = "event";
export const DASHBOARD = "dashboard";
export const NOTIFICATION_SETTINGS = "notificationSettings";
export const GROUP_MEMBERS = "groupMembers";
export const COUNTRY_FILTER = "countriesFilterSlice";
export const COUNTRY_FILTER_SEARCH = "countriesFilterSearch";
export const ACTIVE_COUNTRIES = "activeCountriesSlice";
export const SURVEY = "survey";
export const SURVEY_FORM = "surveyForm";
export const SUGGESTION_DISMISS = "suggestionDismiss";
export const VeevaIntegration = "veevaIntegration";
export const Modal = "modal";
export const USECASE = "usecase";
