import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterName, FilterType } from '@administration/interfaces/IDashboardAdoption';

interface IFilterState {
  filterState: Record<FilterName, { name: string; selectedFilters: any }>;
  filterType: string;
  debug: boolean;
}

interface ISetFiltersPayload {
    filter: any;
    filterName: FilterName;
    filterType: FilterType;
}

const initialState: IFilterState = {
  filterState: {
    products: { name: 'products', selectedFilters: [] },
    countries: { name: 'countries', selectedFilters: [] },
    date: {
      name: 'date',
      selectedFilters: {
        type: 'last_year',
        date: {
          start_date: null,
          end_date: null,
        },
      },
    },
  },
  filterType: 'overview',
  debug: false,
};

const dashboardAdoptionOverviewFilterSlice = createSlice({
  name: 'dashboardAdoptionOverviewFilter',
  initialState,
  reducers: {
    setFilter: (
      state,
      action: PayloadAction<ISetFiltersPayload>,
    ) => {
      switch (action.payload.filterName) {
        case 'countries':
        case 'products':
          state.filterState[action.payload.filterName].selectedFilters = action.payload.filter;
          break;
        case 'date':
          if (action.payload.filterType === 'normal') {
            state.filterState.date.selectedFilters.type = action.payload.filter;
          } else {
            state.filterState.date.selectedFilters.type = 'custom';
            state.filterState.date.selectedFilters.date.start_date =
              action.payload.filter.start_date;
            state.filterState.date.selectedFilters.date.end_date = action.payload.filter.end_date;
          }
          break;
        default:
          break;
      }
    },
    setFilterType: (state, action: PayloadAction<string>) => {
      state.filterType = action.payload;
    },
    setDebug: (state, action: PayloadAction<boolean>) => {
      state.debug = action.payload;
    },
  },
});

export const { setFilter, setFilterType, setDebug } = dashboardAdoptionOverviewFilterSlice.actions;
export default dashboardAdoptionOverviewFilterSlice.reducer;
