import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser } from '@common/interfaces/IUser';
import { fetchUsers } from '../actions/user-manager.action';

export interface IUserManagerState {
  usersToBeInclude: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null;
    values: IUser[];
    query?: string;
  };
  usersToBeExclude: {
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
    error: string | null;
    values: IUser[];
    query?: string;
  };
}

const initialState: IUserManagerState = {
  usersToBeInclude: {
    loading: 'idle',
    error: null,
    values: [],
    query: '',
  },
  usersToBeExclude: {
    loading: 'idle',
    error: null,
    values: [],
    query: '',
  },
};

const usecaseUserManagerSlice = createSlice({
  name: 'usecaseUserManager',
  initialState,
  reducers: {
    clearUsers: () => initialState,
    setUsersToInclude: (state, action: PayloadAction<IUser[]>) => {
      state.usersToBeInclude.values = action.payload;
    },
    setUsersToExclude: (state, action: PayloadAction<IUser[]>) => {
      state.usersToBeExclude.values = action.payload;
    },
    setIncludedQuery: (state, action: PayloadAction<string>) => {
      state.usersToBeInclude.query = action.payload;
    },
    setExcludedQuery: (state, action: PayloadAction<string>) => {
      state.usersToBeExclude.query = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.usersToBeInclude.loading = 'pending';
        state.usersToBeExclude.loading = 'pending';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.usersToBeExclude.loading = 'succeeded';
        state.usersToBeInclude.loading = 'succeeded';
        if (action.meta.arg.type === 'INCLUDE') {
          state.usersToBeInclude.values = action.payload.users || [];
        } else {
          state.usersToBeExclude.values = action.payload.users || [];
        }
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.usersToBeInclude.loading = 'failed';
        state.usersToBeExclude.loading = 'failed';
        if (action.meta.arg.type === 'INCLUDE') {
          state.usersToBeInclude.error = action.error.message || 'GLOBAL_FETCHING_ERROR';
        } else {
          state.usersToBeExclude.error = action.error.message || 'GLOBAL_FETCHING_ERROR';
        }
      });
  },
});

export const {
  clearUsers,
  setUsersToInclude,
  setUsersToExclude,
  setExcludedQuery,
  setIncludedQuery,
} = usecaseUserManagerSlice.actions;
export default usecaseUserManagerSlice.reducer;
