import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IEventCall } from '../../interfaces/IEvent';

export const getEvents = async ({ countryId, productId }: IEventCall): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/policies/events`, {
    params: {
      country_id: countryId,
      product_id: productId,
    },
  });
};

export const getEventTypes = async ({
  countryId,
  productId,
}: IEventCall): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/policies/events/types`, {
    params: {
      country_id: countryId,
      product_id: productId,
    },
  });
};

export const getEventStatus = async ({
  countryId,
  productId,
}: IEventCall): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/policies/events/statuses`, {
    params: {
      country_id: countryId,
      product_id: productId,
    },
  });
};

export const getEventTopics = async ({
  countryId,
  productId,
}: IEventCall): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/policies/events/topics`, {
    params: {
      country_id: countryId,
      product_id: productId,
    },
  });
};

export const getEventAttendees = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/policies/events/attendees/statuses`);
};

export const getEventBulk = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/events/bulk?schema=true&format=csv`);
};

export const getEventAttendeesBulk = async (): Promise<AxiosResponse> => {
  return instance.get(
    `${config.REACT_APP_API_VERSION}/events/attendees/bulk?schema=true&format=csv`,
  );
};

export const getEventSpeakerBulk = async (): Promise<AxiosResponse> => {
  return instance.get(
    `${config.REACT_APP_API_VERSION}/events/speakers/bulk?schema=true&format=csv`,
  );
};
