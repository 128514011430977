import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MRT_PaginationState } from 'material-react-table';
import { getGroupMembers } from '@administration/apis/groups';
import { IGroupMembers } from '@administration/interfaces/IGroups';

export const fetchGroupMembers = createAsyncThunk(
  'groupMembers/fetchGroupMembers',
  async ({
    groupId,
    query,
    pagination,
  }: {
    groupId: string;
    query?: string;
    pagination: MRT_PaginationState;
  }) => {
    const response = await getGroupMembers(
      groupId,
      query,
      pagination.pageIndex,
      pagination.pageSize,
    );
    return response.data;
  },
);

const initialState: IGroupMembers = {
  groupMembersLoading: false,
  groupMembers: [],
  groupMembersCount: 0,
  groupMembersError: null,
  searchQuery: '',
};

const GroupMembersSlice = createSlice({
  name: 'groupMembers',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupMembers.pending, (state) => {
        state.groupMembersLoading = true;
        state.groupMembersError = null;
      })
      .addCase(fetchGroupMembers.fulfilled, (state, action) => {
        state.groupMembersLoading = false;
        state.groupMembers = action.payload.members;
        state.groupMembersCount = action.payload.$count;
      })
      .addCase(fetchGroupMembers.rejected, (state, action) => {
        state.groupMembersLoading = false;
        state.groupMembersError = action.payload as any;
        state.groupMembersCount = 0;
      });
  },
});

export const { setSearchQuery } = GroupMembersSlice.actions;
export default GroupMembersSlice.reducer;
