import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IFilter,
  ISuggestionByProducts,
  ITopUsers,
  ISuggestionsSentByTime,
  ISuggestionByUseCase,
  IActiveUsersByTime,
  IUseCase,
  IKAM,
  IKamCategoryUser,
} from '@administration/interfaces/IDashboardAdoption';
import {
  getDashboardAdoptionOverview,
  getDashboardAdoptionUseCase,
  getDashboardAdoptionKam,
} from '@administration/apis/dashboard';

const initialState = {
  dashboardAdoptionOverview: {
    suggestions_completed_percent: 0,
    suggestions_dismissed_percent: 0,
    suggestions_actioned: 0,
    suggestions_actioned_percent: 0,
    suggestions_completed: 0,
    suggestions_dismissed: 0,
    suggestions_with_interaction: 0,
    suggestions_with_interaction_percent: 0,
    meeting_planned: 0,
    meeting_planned_percent: 0,
    meeting_realised: 0,
    meeting_realised_percent: 0,
    suggestions_by_products: [] as ISuggestionByProducts[],
    top_users: [] as ITopUsers[],
    suggestions_sent_by_time: {} as ISuggestionsSentByTime,
    suggestions_by_usecase: [] as ISuggestionByUseCase[],
    active_users_by_time: {} as IActiveUsersByTime,
  },
  usecases: [] as IUseCase[],
  kam: {
    top_users: [],
    categories_users: {
      active_user: {} as IKamCategoryUser,
      passive_user: {} as IKamCategoryUser,
    },
  } as IKAM,
  loading: false,
  error: null,
};

export const fetchDashboardAdoptionOverview = createAsyncThunk(
  'dashboardAdoptionOverview/fetchDashboardAdoptionOverview',
  async ({ filters, debug }: { filters: IFilter | null; debug: boolean }) => {
    try {
      const response = await getDashboardAdoptionOverview({
        filters,
        debug,
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching dashboard overview');
    } catch (error) {
      throw new Error('An error occurred while fetching dashboard overview');
    }
  },
);

export const fetchDashboardAdoptionUseCase = createAsyncThunk(
  'dashboardAdoptionOverview/fetchDashboardAdoptionUseCase',
  async ({ filters, debug }: { filters: IFilter | null; debug: boolean }) => {
    try {
      const response = await getDashboardAdoptionUseCase({
        filters,
        debug,
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching dashboard usecases');
    } catch (error) {
      throw new Error('An error occurred while fetching dashboard usecases');
    }
  },
);

export const fetchDashboardAdoptionUseKam = createAsyncThunk(
  'dashboardAdoptionOverview/fetchDashboardAdoptionUseKam',
  async ({ filters, debug }: { filters: IFilter | null; debug: boolean }) => {
    try {
      const response = await getDashboardAdoptionKam({
        filters,
        debug,
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching dashboard usecases');
    } catch (error) {
      throw new Error('An error occurred while fetching dashboard usecases');
    }
  },
);

const dashboardAdoptionOverviewSlice = createSlice({
  name: 'dashboardAdoptionOverview',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Dashboard Overview
      .addCase(fetchDashboardAdoptionOverview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionOverview.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardAdoptionOverview = action.payload.values;
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionOverview.rejected, (state) => {
        state.loading = false;
        state.error = null;
      })
      // Dashboard UseCase
      .addCase(fetchDashboardAdoptionUseCase.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionUseCase.fulfilled, (state, action) => {
        state.loading = false;
        state.usecases = action.payload.values['suggestions_by_usecase'] || [];
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionUseCase.rejected, (state) => {
        state.loading = false;
        state.error = null;
      })

      // Dashboard Kam
      .addCase(fetchDashboardAdoptionUseKam.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionUseKam.fulfilled, (state, action) => {
        state.loading = false;
        state.kam = action.payload.values || ({} as IKAM);
        state.error = null;
      })
      .addCase(fetchDashboardAdoptionUseKam.rejected, (state) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export default dashboardAdoptionOverviewSlice.reducer;
