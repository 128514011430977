import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { searchUsers } from '../../../../../apis/users/users';
import { IUserData, IInitialState } from '../../../../../interfaces/IAdminUsers';
import { IQueryKey } from '@administration/interfaces/IQueryKey';
import { deleteUser } from '@administration/apis/users/users';

export const searchForUsers = createAsyncThunk<
  IUserData,
  {
    queryKey: IQueryKey;
    organizationID?: string | undefined;
    expand?: string;
    extra_columns?: string;
  }
>('users/searchForUsers', async ({ queryKey, organizationID, expand, extra_columns }) => {
  const response = await searchUsers(queryKey, organizationID, expand, extra_columns);
  if (response.status !== 200) {
    throw new Error('Failed to fetch users');
  }
  return response.data;
});

export const deleteUsers = createAsyncThunk(
  'users/deleteUsers',
  async ({ user_ids }: { user_ids: string[] }) => {
    const response = await Promise.all(user_ids.map(async (id) => deleteUser(id)));
    if (response.some((res) => res.status !== 201)) {
      throw new Error('Failed to delete users');
    }
    return null;
  },
);

const initialState: IInitialState = {
  users: [],
  count: 0,
  cursor: null,
  loading: false,
  error: false,
  deleteLoading: false,
  deleteError: false,
};

const AdminUsersSlice = createSlice({
  name: 'AdminUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // SearchForUsers
    builder.addCase(searchForUsers.fulfilled, (state, action: any) => {
      state.users = action.payload.values;
      state.loading = false;
      state.count = action.payload['$count'];
    });
    builder.addCase(searchForUsers.pending, (state) => {
      state.users = [];
      state.error = false;
      state.loading = true;
    });
    builder.addCase(searchForUsers.rejected, (state) => {
      state.users = [];
      state.loading = false;
      state.error = true;
    });
    // DeleteUsers
    builder.addCase(deleteUsers.fulfilled, (state) => {
      state.deleteLoading = false;
      state.deleteError = false;
    });
    builder.addCase(deleteUsers.pending, (state) => {
      state.deleteLoading = true;
      state.deleteError = false;
    });
    builder.addCase(deleteUsers.rejected, (state) => {
      state.deleteLoading = false;
      state.deleteError = true;
    });
  },
});

export default AdminUsersSlice.reducer;
