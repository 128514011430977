import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IConfigurationItem } from "@administration/interfaces/IUsecases";

interface ISalesInterface {
  isActive: boolean;
  configuration: {
    low_threshold: number;
    alert_high_loss: number;
    alert_very_high_loss: number;
  };
}

const initialState: ISalesInterface = {
  isActive: false,
  configuration: {
    low_threshold: 0,
    alert_high_loss: 0,
    alert_very_high_loss: 0,
  },
};

const SalesSlice = createSlice({
    name:"usecase/sales",
    initialState,
    reducers:{
        setActive(state, action:PayloadAction<boolean>){
            state.isActive = action.payload
        },
        setConfiguration(state, action:PayloadAction<{ key : keyof typeof state.configuration, value : number}>){
            const { key, value } = action.payload
            state.configuration[key] = value;
        },
        clearSales(state){
            state.isActive = false;
            state.configuration = {
                low_threshold: 0,
                alert_high_loss: 0,
                alert_very_high_loss: 0,
            }
        },
        prepareSalesForEdit(state, action:PayloadAction<IConfigurationItem>){
            const salesConfig = action.payload.configuration;
            state.isActive = action.payload.active;
            state.configuration = {
                low_threshold: salesConfig?.low_threshold,
                alert_high_loss: salesConfig?.alert_high_loss,
                alert_very_high_loss: salesConfig?.alert_very_high_loss,
            }   
        }
    }
})

export const { setActive, setConfiguration, clearSales, prepareSalesForEdit } = SalesSlice.actions
export default SalesSlice.reducer;
