import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/types';
import { parseDistance, parseDuration } from '../helpers';
import { IDirectionsState, JourneyErrorType } from '@common/interfaces/IMap';
import { MAXIMUM_DISTANCE_COVERED } from '../constants';

export const planUserJourney = createAsyncThunk<
  {
    target: string;
    origin: google.maps.LatLngLiteral;
    waypoints: google.maps.DirectionsWaypoint[] | null;
    destination: google.maps.LatLngLiteral | null;
    showDirections: boolean;
  },
  void,
  {
    state: RootState;
    rejectValue: JourneyErrorType;
  }
>('directions/planUserJourney', async (_, { getState, rejectWithValue }) => {
  const storeState = getState() as RootState;
  const uniqueHcps = storeState.suggestions.uniqueHcps;
  const userLocation = storeState.map.userLocation;

  if (!userLocation) {
    return rejectWithValue('USER_LOCATION_UNDETERMINED');
  }

  try {
    const { DirectionsService } = (await google.maps.importLibrary(
      'routes',
    )) as google.maps.RoutesLibrary;

    if (!DirectionsService) {
      return rejectWithValue('GLOBAL_ERROR');
    }

    const directionsService = new DirectionsService();

    const routePromises = uniqueHcps.map(
      (hcp: any) =>
        new Promise<{ duration: string; distance: string; hcp: any }>((resolve, reject) => {
          directionsService.route(
            {
              origin: userLocation,
              destination: { lat: hcp.addresses[0].latitude, lng: hcp.addresses[0].longitude },
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (response, status) => {
              if (status === google.maps.DirectionsStatus.OK && response) {
                resolve({
                  duration: response.routes[0]?.legs[0]?.duration?.text || 'Unknown',
                  distance: response.routes[0]?.legs[0]?.distance?.text || 'Unknown',
                  hcp,
                });
              } else {
                reject('NO_ROUTES_FOUND');
              }
            },
          );
        }),
    );

    const results = await Promise.all(routePromises);
    const sortedResults = results
      .map(({ duration, distance, hcp }) => ({
        duration: parseDuration(duration),
        distance: parseDistance(distance),
        hcp,
      }))
      .sort((a, b) => a.duration - b.duration);

    const nearestHCPs = sortedResults
      .slice(0, 4)
      .filter(({ distance }) => distance < MAXIMUM_DISTANCE_COVERED)
      .map(({ hcp }) => hcp);

    if (nearestHCPs.length === 0) {
      return rejectWithValue('NO_NEARBY_HCP');
    }

    const lastPointIndex = nearestHCPs.length - 1;
    const waypoints = nearestHCPs.slice(0, lastPointIndex).map((hcp) => ({
      location: { lat: hcp.addresses[0].latitude, lng: hcp.addresses[0].longitude },
      stopover: true,
    }));
    const destination = {
      lat: nearestHCPs[lastPointIndex].addresses[0].latitude,
      lng: nearestHCPs[lastPointIndex].addresses[0].longitude,
    };

    return {
      target: nearestHCPs[lastPointIndex].displayName,
      origin: userLocation,
      waypoints,
      destination,
      showDirections: true,
    };
  } catch (error) {
    return rejectWithValue('GLOBAL_ERROR');
  }
});

const initialState: IDirectionsState = {
  journeyError: null,
  journeyIsPlanning: false,
  target: '',
  showDirections: false,
  waypoints: null,
  origin: null,
  destination: null,
};

const directionsSlice = createSlice({
  name: 'directions',
  initialState,
  reducers: {
    enableDirections: (state) => {
      state.showDirections = true;
    },
    setTarget: (state, action) => {
      state.target = action.payload;
    },
    setOrigin: (state, action) => {
      state.origin = action.payload;
    },
    setDestination: (state, action) => {
      state.destination = action.payload;
    },
    setWaypoints: (state, action) => {
      state.waypoints = action.payload;
    },
    setDirections: (state, action) => {
      state.showDirections = true;
      state.waypoints = action.payload.waypoints;
      state.origin = action.payload.origin;
      state.destination = action.payload.destination;
    },
    clearDirections: (state) => {
      state.target = '';
      state.showDirections = false;
      state.waypoints = null;
      state.origin = null;
      state.destination = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(planUserJourney.pending, (state) => {
      state.journeyIsPlanning = true;
    });
    builder.addCase(planUserJourney.fulfilled, (state, action) => {
      state.target = action.payload.target;
      state.journeyIsPlanning = false;
      state.waypoints = action.payload.waypoints;
      state.origin = action.payload.origin;
      state.destination = action.payload.destination;
      state.showDirections = action.payload.showDirections;
      state.journeyError = null;
    });
    builder.addCase(planUserJourney.rejected, (state, action) => {
      state.journeyIsPlanning = false;
      state.journeyError = (action.payload as JourneyErrorType) || 'GLOBAL_ERROR';
    });
  },
});

export const {
  enableDirections,
  setTarget,
  setOrigin,
  setDestination,
  setWaypoints,
  clearDirections,
  setDirections,
} = directionsSlice.actions;
export default directionsSlice.reducer;
