import { combineReducers } from '@reduxjs/toolkit';
import ParamatersReducer from './paramaters.slice';
import UserManagerReducer from './user-manager.slice';
import EventReducer from './event.slice';
import MarketShareReducer from './marketshare.slice';
import SurveyReducer from './usecase-survey.slice';
import ApprovedEmailReducer from './approved_email.slice';
import PanelReducer from './usecase-panel.slice';
import UsecasesReducer from './usecases.slice';
import AudienceReducer from './audience.slice';
import SalesReducer from './sales.slice';

const UsecaseReduces = combineReducers({
  paramaters: ParamatersReducer,
  users: UserManagerReducer,
  event: EventReducer,
  marketshare: MarketShareReducer,
  survey: SurveyReducer,
  approvedEmail: ApprovedEmailReducer,
  panel: PanelReducer,
  usecases: UsecasesReducer,
  audience: AudienceReducer,
  sales: SalesReducer,
});

export default UsecaseReduces;
