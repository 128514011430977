import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationsApi } from './notifications.api';
import { setIsDeleting } from './notifications-form.slice';

export const deleteNotificationSettings = createAsyncThunk(
  'notifications/deleteNotification',
  async (notificationId: string, { dispatch }) => {
    dispatch(setIsDeleting(true));
    try {
      await dispatch(
        NotificationsApi.endpoints.deleteNotificationSettings.initiate(notificationId),
      ).unwrap();
      dispatch(setIsDeleting(false));
    } catch (_error) {
      dispatch(setIsDeleting(false));
    }
  },
);
