"use strict";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import suggestionReducer from "../common/pages/suggestions/slices/suggestion.slice";
import sidebarReducer from "../administration/components/sidebar/sidebar-slice";
import userAdminFiltersReducer from "../administration/pages/administration/dashboard/users/slices/filters.slice";
import AdminUsersReducer from "../administration/pages/administration/dashboard/users/slices/admin-users.slice";
import userFilterSearch from "../administration/pages/administration/dashboard/users/slices/search.slice";
import toastReducer from "../administration/components/toast/toast.slice";
import countrySlice from "../administration/pages/administration/dashboard/countries/slices/country.slice";
import usecasePanelReducer from "../administration/pages/administration/dashboard/usecase/slices/usecase-panel.slice";
import usecasesReducer from "../administration/pages/administration/dashboard/usecase/slices/usecases.slice";
import auditReducer from "../administration/pages/administration/dashboard/audit/slices";
import hcpsReducer from "../common/slices/hcpSlice";
import hcosReducer from "../common/slices/hcoSlice";
import productsReducer from "../common/slices/productsSlice";
import usersReducer from "../common/slices/userSlice";
import suggestionsFilterReducer from "../common/components/filters/filter.slice";
import personalSettingsReducer from "../common/pages/settings/settings.slice";
import mapReducer from "../common/pages/suggestions/slices/map.slice";
import directionsReducer from "../common/pages/suggestions/slices/directions.slice";
import themeReducer from "../common/theme/theme.slice";
import userDetailsReducer from "../administration/pages/administration/dashboard/user-page/slices/user-details.slice";
import userDetailsModalReducer from "../administration/pages/administration/dashboard/user-page/slices/details-modal.slice";
import appSetupFormReducer from "../administration/pages/administration/dashboard/app-setup/slices/app-setup.slice";
import MSTeamsSetupReducer from "../administration/pages/administration/dashboard/msteams-setup/slices/msteams-setup.slice";
import MSTeamsActionsReducer from "../administration/pages/administration/dashboard/msteams-setup/slices/msteams-actions.slice";
import eventReducer from "../administration/pages/administration/dashboard/usecase/slices/event.slice";
import dashboardReducer from "../administration/pages/administration/dashboard/dashboard/slices";
import notificationSettingsReducer from "../administration/pages/administration/dashboard/global-settings/slices/notifications-form.slice";
import groupMembersReducer from "../administration/pages/administration/dashboard/groups/slices/groups-members.slice";
import countryFilterReducer from "../administration/pages/administration/dashboard/countries/slices/filter.slice";
import countryFilterSearchReducer from "../administration/pages/administration/dashboard/countries/slices/search.slice";
import activeCountriesReducer from "@administration/pages/administration/dashboard/countries/slices/active-countries.slice";
import surveyReducer from "../administration/pages/administration/dashboard/survey/slices/survey.slice";
import surveyFormReducer from "../administration/pages/administration/dashboard/survey/slices/survey-form.slice";
import suggestionDismissReducer from "../common/pages/dismiss-page/slices/suggestion-dismiss.slice";
import VeevaIntegrationReducer from "../administration/pages/administration/dashboard/veeva-setup/slices/veeva-integration.slice";
import ModalReducer from "../common/components/modal/modal.slice";
import usecaseReducer from "../administration/pages/administration/dashboard/usecase/slices/index";
import { MappersApi } from "../administration/pages/administration/dashboard/veeva-setup/slices/mappers-api.slice";
import { CountryApi } from "../administration/apis/country/country.api";
import { productApi } from "../administration/apis/product/product.api";
import { approvedEmailApi } from "../administration/pages/administration/dashboard/usecase/slices/approved_email.api";
import { NotificationsApi } from "../administration/pages/administration/dashboard/global-settings/slices/notifications.api";
import { VeevaActionApi } from "../administration/pages/administration/dashboard/suggestions-settings/slices/veeva-actions.api";
import {
  ADMIN_SIDEBAR,
  ADMIN_USER_FILTERS,
  ADMIN_USERS,
  AUDIT,
  COUNTRIES,
  SUGGESTIONS,
  TOAST,
  USECASE_PANEL,
  USECASES,
  HCPS,
  HCOS,
  PRODUCTS,
  SUGGESTIONS_FILTER,
  PERSONAL_SETTINGS,
  USERS,
  MAP,
  DIRECTIONS,
  THEME,
  USER_DETAILS,
  USER_DETAILS_MODAL,
  APP_SETUP,
  MS_TEAMS_SETUP,
  MS_TEAMS_SETUP_ACTIONS,
  EVENT,
  DASHBOARD,
  USER_FILTER_SEARCH,
  NOTIFICATION_SETTINGS,
  GROUP_MEMBERS,
  COUNTRY_FILTER,
  COUNTRY_FILTER_SEARCH,
  ACTIVE_COUNTRIES,
  SURVEY,
  SURVEY_FORM,
  SUGGESTION_DISMISS,
  VeevaIntegration,
  Modal,
  USECASE
} from "./constants";
import { confirmationMiddleware, debounceMiddleware } from "./middlewares";
const rootReducer = combineReducers({
  [SUGGESTIONS]: suggestionReducer,
  [ADMIN_SIDEBAR]: sidebarReducer,
  [ADMIN_USERS]: AdminUsersReducer,
  [ADMIN_USER_FILTERS]: userAdminFiltersReducer,
  [TOAST]: toastReducer,
  [COUNTRIES]: countrySlice,
  [USECASE_PANEL]: usecasePanelReducer,
  [USECASES]: usecasesReducer,
  [AUDIT]: auditReducer,
  [HCPS]: hcpsReducer,
  [HCOS]: hcosReducer,
  [PRODUCTS]: productsReducer,
  [USERS]: usersReducer,
  [SUGGESTIONS_FILTER]: suggestionsFilterReducer,
  [PERSONAL_SETTINGS]: personalSettingsReducer,
  [MAP]: mapReducer,
  [DIRECTIONS]: directionsReducer,
  [THEME]: themeReducer,
  [USER_DETAILS]: userDetailsReducer,
  [USER_DETAILS_MODAL]: userDetailsModalReducer,
  [APP_SETUP]: appSetupFormReducer,
  [MS_TEAMS_SETUP]: MSTeamsSetupReducer,
  [MS_TEAMS_SETUP_ACTIONS]: MSTeamsActionsReducer,
  [EVENT]: eventReducer,
  [DASHBOARD]: dashboardReducer,
  [USER_FILTER_SEARCH]: userFilterSearch,
  [NOTIFICATION_SETTINGS]: notificationSettingsReducer,
  [GROUP_MEMBERS]: groupMembersReducer,
  [COUNTRY_FILTER]: countryFilterReducer,
  [COUNTRY_FILTER_SEARCH]: countryFilterSearchReducer,
  [ACTIVE_COUNTRIES]: activeCountriesReducer,
  [SURVEY]: surveyReducer,
  [SURVEY_FORM]: surveyFormReducer,
  [SUGGESTION_DISMISS]: suggestionDismissReducer,
  [VeevaIntegration]: VeevaIntegrationReducer,
  [Modal]: ModalReducer,
  [USECASE]: usecaseReducer,
  [MappersApi.reducerPath]: MappersApi.reducer,
  [CountryApi.reducerPath]: CountryApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [approvedEmailApi.reducerPath]: approvedEmailApi.reducer,
  [NotificationsApi.reducerPath]: NotificationsApi.reducer,
  [VeevaActionApi.reducerPath]: VeevaActionApi.reducer
});
export const store = configureStore({
  reducer: rootReducer,
  middleware: (gdm) => gdm().concat(debounceMiddleware).concat(confirmationMiddleware).concat(MappersApi.middleware).concat(CountryApi.middleware).concat(productApi.middleware).concat(approvedEmailApi.middleware).concat(VeevaActionApi.middleware).concat(NotificationsApi.middleware)
});
export const storeTest = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
};
