import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUsecasesInitialState } from '../../../../../interfaces/IUsecases';
import {
  deleteSelectedUsecases,
  fetchUsecases,
  toggleUsecaseStatus,
} from '../actions/usecase.action';
import { MRT_RowSelectionState } from 'material-react-table';

const initialState: IUsecasesInitialState = {
  usecases: [],
  count: 0,
  loading: 'idle',
  error: undefined,
};

const usecasesSlice = createSlice({
  name: 'usecases',
  initialState,
  reducers: {
    setSelectedRows: (state, action: PayloadAction<MRT_RowSelectionState>) => {
      state.selectedRows = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsecases.fulfilled, (state, action: PayloadAction<any>) => {
        state.usecases = action.payload.values;
        state.count = action.payload['$count'];
        state.loading = 'succeeded';
        state.error = undefined;
      })
      .addCase(fetchUsecases.rejected, (state, _action: PayloadAction<any>) => {
        state.usecases = [];
        state.count = 0;
        state.loading = 'failed';
        state.error = 'GLOBAL_FETCHING_ERROR';
      })
      .addCase(fetchUsecases.pending, (state, _action: PayloadAction<any>) => {
        state.usecases = [];
        state.count = 0;
        state.loading = 'pending';
        state.error = undefined;
      })
      .addCase(
        toggleUsecaseStatus.fulfilled,
        (state, action: PayloadAction<{ id: string; status: string }>) => {
          const { id, status } = action.payload;
          const usecase = state.usecases.find((usecase) => usecase.id === id);
          if (usecase) {
            usecase.status = status;
            usecase.isToggling = false;
          }
        },
      )
      .addCase(toggleUsecaseStatus.rejected, (state) => {
        state.error = 'GLOBAL_ERROR';
      })
      .addCase(toggleUsecaseStatus.pending, (state, action) => {
        state.error = undefined;
        const usecase = state.usecases.find((u) => u.id === action.meta.arg.id);
        if (usecase) {
          usecase.isToggling = true;
        }
      })
      .addCase(deleteSelectedUsecases.fulfilled, (state, action: PayloadAction<string[]>) => {
        const selectedRowsIds = action.payload;
        state.usecases = state.usecases.filter((usecase) => !selectedRowsIds.includes(usecase.id));
        state.count = Number(state.count) - Number(selectedRowsIds.length);
        state.loading = 'succeeded';
        state.selectedRows = {};
      })
      .addCase(deleteSelectedUsecases.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteSelectedUsecases.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const { setSelectedRows } = usecasesSlice.actions;
export default usecasesSlice.reducer;
