import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountryResponse } from '@administration/interfaces/ICountry';
import { IProductResponse } from '@administration/interfaces/IProduct';
import { WEEK_DAYS } from '../constants';
import { CadenceWeek } from '@administration/interfaces/INotifications';

type DayOfWeek = {
  label: string;
  value: string;
  selected: boolean;
  ariaLabel: string;
};

type TypeCadenceInterval = 'weekly' | 'monthly';
type TypeSettingsType = 'global' | 'specific';

interface NotificationSettingsState {
  notificationSettings: {
    notificationId: string | null;
    cadenceInterval: TypeCadenceInterval;
    settingsType: TypeSettingsType;
    country: ICountryResponse | null;
    product: IProductResponse | null;
    days: DayOfWeek[];
    volume: number;
    onDay: string;
    monthCadence: string;
    shouldSeeMore: boolean;
  };
  isDeleting: boolean;
}

const initialState: NotificationSettingsState = {
  notificationSettings: {
    notificationId: null,
    cadenceInterval: 'monthly',
    settingsType: 'global',
    country: null,
    product: null,
    days: WEEK_DAYS,
    volume: 10,
    onDay: '1',
    monthCadence: '1',
    shouldSeeMore: true,
  },
  isDeleting: false,
};

const notificationSettingsSlice = createSlice({
  name: 'notificationSettings',
  initialState,
  reducers: {
    setCadenceInterval: (state, action: PayloadAction<TypeCadenceInterval>) => {
      state.notificationSettings.cadenceInterval = action.payload;
    },
    setSettingsType: (state, action: PayloadAction<TypeSettingsType>) => {
      state.notificationSettings.settingsType = action.payload;
    },
    setSelectedCountry: (state, action: PayloadAction<any | null>) => {
      if (action.payload === 'ALL') {
        state.notificationSettings.country = {
          id: 'ALL',
          name: 'ALL',
        } as ICountryResponse;
      } else {
        state.notificationSettings.country = action.payload;
      }
    },
    setSelectedProduct: (state, action: PayloadAction<any | null>) => {
      if (action.payload === 'ALL') {
        state.notificationSettings.product = {
          id: 'ALL',
          name: 'ALL',
        } as IProductResponse;
      } else {
        state.notificationSettings.product = action.payload;
      }
    },
    setDays: (state, action: PayloadAction<DayOfWeek[]>) => {
      state.notificationSettings.days = action.payload;
    },
    setVolume: (state, action: PayloadAction<number>) => {
      state.notificationSettings.volume = action.payload;
    },
    setOnDay: (state, action: PayloadAction<string>) => {
      state.notificationSettings.onDay = action.payload;
    },
    setMonthCadence: (state, action: PayloadAction<string>) => {
      state.notificationSettings.monthCadence = action.payload;
    },
    setShouldSeeMore: (state, action: PayloadAction<boolean>) => {
      state.notificationSettings.shouldSeeMore = action.payload;
    },
    toggleDaySelection: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      state.notificationSettings.days[index].selected =
        !state.notificationSettings.days[index].selected;
    },
    displaySelectedDays: (state, action: PayloadAction<CadenceWeek>) => {
      const data = action.payload;
      state.notificationSettings.days = state.notificationSettings.days.map((day) => ({
        ...day,
        selected: !!data.days.find((x: any) => x.label === day.label),
      }));
    },
    clearState: (state) => {
      state.notificationSettings.notificationId = null;
      state.notificationSettings.settingsType = 'global';
      state.notificationSettings.cadenceInterval = 'monthly';
      state.notificationSettings.country = null;
      state.notificationSettings.product = null;
      state.notificationSettings.volume = 10;
      state.notificationSettings.onDay = '1';
      state.notificationSettings.monthCadence = '1';
      state.notificationSettings.shouldSeeMore = true;
      state.notificationSettings.days = state.notificationSettings.days.map((day) => ({
        ...day,
        selected: false,
      }));
    },
    setIsDeleting: (state, action: PayloadAction<boolean>) => {
      state.isDeleting = action.payload;
    },
    prepareNotificationForEdit: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      state.notificationSettings.notificationId = data.id;
      state.notificationSettings.settingsType = data.type;
      state.notificationSettings.volume = data.volume;
      state.notificationSettings.shouldSeeMore = data.enable_user_setting;

      if (data.cadence.per === 'week') {
        state.notificationSettings.cadenceInterval = 'weekly';
        state.notificationSettings.days = state.notificationSettings.days.map((day) => ({
          ...day,
          selected: !!data.cadence.days.find((x: any) => x.label === day.label),
        }));
      } else {
        state.notificationSettings.cadenceInterval = 'monthly';
        state.notificationSettings.onDay = data.cadence.days[0];
      }
    },
  },
});

export const {
  setCadenceInterval,
  setSettingsType,
  setSelectedCountry,
  setSelectedProduct,
  setDays,
  setVolume,
  setOnDay,
  setMonthCadence,
  displaySelectedDays,
  setShouldSeeMore,
  toggleDaySelection,
  clearState,
  setIsDeleting,
  prepareNotificationForEdit,
} = notificationSettingsSlice.actions;

export default notificationSettingsSlice.reducer;
